const idPortalApi = "1d49c8e8aea4517208f238e8caa620b2ced8bc1c";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://www.cmjoselandia.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmjoselandia.ma.gov.br",
  NomePortal: "Joselândia",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `https://www.planalto.gov.br/ccivil_03/leis/l9504.htm`,
  link_localize: `https://maps.app.goo.gl/ejGMfey63fyQ6nQQ6`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19610.269183083143!2d-44.702501169910065!3d-4.9809936488479805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x789b60366d21a73%3A0x4b65f3199915e9f9!2sJosel%C3%A2ndia%2C%20State%20of%20Maranh%C3%A3o%2C%2065755-000!5e0!3m2!1sen!2sbr!4v1721762504248!5m2!1sen!2sbr",
};
